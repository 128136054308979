import { render, staticRenderFns } from "./decorationBardian.vue?vue&type=template&id=c5fdc49c&scoped=true&"
import script from "./decorationBardian.vue?vue&type=script&lang=js&"
export * from "./decorationBardian.vue?vue&type=script&lang=js&"
import style0 from "./decorationBardian.vue?vue&type=style&index=0&id=c5fdc49c&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5fdc49c",
  null
  
)

export default component.exports