<template>
	<div class="bardian" v-title="'个性定制家装——千家美装饰室内装修公司_房屋装修设计_家装设计报价'">
		<NavBar></NavBar>
		<div class="box">
			<img
				class="bran"
				src="../image/decoration/bardian_header_pc.jpg"
				alt=""
			/>
		</div>
        <div class="box-mob">
            <img
				class="bran"
				src="../image/decoration/bardian_header_mob.jpg"
				alt=""
			/>
		</div>
		<div class="content f-y-s-c">
			<div class="box1 f-x-b-c">
                <div class="form-box">
                    <VisitorForm4 
                        :showAddress="false"
                        :showHouseType="false"
                        title="个性定制家装" 
                        desc="免费快速获取整体家装方案"
                        adUnitCode="D8H5"
                    >
                    </VisitorForm4>
                </div>
				
				<img
					src="../image/decoration/whole_box1.png"
					alt=""
					class="img1"
				/>
			</div>
			<div class="box2 f-y-s-c">
				<div class="header f-y-c-c">
					<span class="text1">个性化定制</span>
					<span class="text2"
						>空间美+科技化+智能化+人性化崭新生活的起点
					</span>
				</div>
				<div class="box2-content f-x-b-c">
					<div class="list-item">
                        <img class="img1" src="../image/decoration/bardian_box2_1.jpg" alt="">
                        <div class="title f-x-c-c">专属化</div>
					</div>
                    <div class="list-item">
                        <img class="img1" src="../image/decoration/bardian_box2_2.jpg" alt="">
                        <div class="title f-x-c-c">智能化</div>
					</div>
                    <div class="list-item">
                        <img class="img1" src="../image/decoration/bardian_box2_3.jpg" alt="">
                        <div class="title f-x-c-c">人性化</div>
					</div>
                    <div class="list-item">
                        <img class="img1" src="../image/decoration/bardian_box2_4.jpg" alt="">
                        <div class="title f-x-c-c">极简化</div>
					</div>
				</div>
			</div>
			<div class="box3">
				<div class="header f-y-c-c">
					<span class="text1">专属新家定制指南</span>
					<span class="text2"
						>颜值 + 需求 + 便捷 + 舒适度 + 独特性 + 幸福感 + 风格</span
					>
				</div>
				<div class="box3-content f-y-c-c">
					<div class="tab-list f-x-b-c">
						<div
							v-for="(item, index) in tabList"
							:key="index"
							class="list-item f-x-c-c"
							:class="curTab == index ? 'tab-active' : ''"
							@click="cahngeTabs(index)"
						>
							{{ item.title }}
						</div>
					</div>
					<div class="banner">
						<img class="img4" :src="tabList[curTab].url" alt="" />
					</div>
				</div>
			</div>
			<div class="box4 f-y-c-c">
				<div class="header f-y-c-c">
					<span class="text1">小空间也能有大想象</span>
					<span class="text2"
						>艺术设计 + 极致收纳 = 简约至上</span
					>
				</div>
				<div class="box4-content ">
                    <div class="left">
                        <img class="img" src="../image/decoration/bardian_box4_bg.jpg" alt="">
                    </div>
					<div class="list">
						<div 
                            v-for="(item,index) in list3"
                            :key="index"
                            class="list-item f-x-c-c">
                            <img :src="item.url" alt="" class="img">
                            <div class="info f-y-c-s">
                                <span class="text3">{{item.title}}</span>
                                <p class="text4">{{item.des}}</p>
                            </div>
                        </div>
					</div>
				</div>
                <div class="box4-content1 mt15 f-y-c-c">
                    <swiper v-if="list3.length" class="swiper" :options="optionSmall" :key="optionSmall.slidesPerView" ref="refSmall">
                        <swiper-slide  v-for="(item, index) in list3" :key="item.id" class="swiper-item">
                            <img class="small-img" :src="item.url" alt="">
                            <div class="small-title mt15">
                                <div :class="'triangle'+index" class="triangle"></div>
                                <div class="title-content f-y-c-s mt30"> 
                                    <div class="title-top f-x-c-c">
                                        <span >{{item.title}}</span>
                                        <div class="line ml5" style="height: 1px;"></div>
                                    </div>
                                    <span class="title-bottom mt8">
                                        {{item.des}}
                                    </span>
                                </div>
                            </div>
                        </swiper-slide>
                    </Swiper>
                    <div class="img-list f-x-b-c">
                        <div
                            v-for="(item,index) in list3"
                            :key="index"
                            @click="clickSmall(index)" 
                            class="list-item">
                            <img class="item-img" :src="item.url">
                        </div>
                    </div>
                </div>
			</div>
            <div class="box5" v-if="desList.length">
				<div class="header f-y-c-c">
					<span class="text1">打造专属整装定制设计师</span>
					<span class="text2"
						>户型规划设计 + 1V1专属设计师服务</span
					>
				</div>
				<div class="box5-content ">
                    <div class="info f-y-c-c">
                        <div class="top f-x-c-c">
                            <img class="info-img" :src="curDesInfo.headerImgUrl" alt="">
                            <div class="info-desc f-y-s-s">
                                <div class="header f-x-s-e">
                                    <span class="text1">{{curDesInfo.name}}</span>
                                    <span class="text2">{{ curDesInfo.ranks && formartData( 'ranks',curDesInfo.ranks) }}</span>
                                </div>
                                <span class="text3">从业经验：{{ curDesInfo.workingLife }} 年</span>
                                <p class="text4">擅长风格：{{ curDesInfo.designStyle}}</p>
                                <div @click="openDialog" class="btn f-x-c-c">找TA设计</div>
                            </div>
                        </div>
                        <div class="bottom f-y-s-c">
                            <div class="header f-x-s-c">
                                <span class="title">代表案例</span>
                                <div class="line"></div>
                            </div>
                            <div class="carousel">
                                <swiper
                                    class="swiper"
                                    :options="swiperOption1"
                                    ref="mySwiper1"
                                >
                                    <swiper-slide 
                                        v-for="(item,index) in curCaseList"
                                        :key="index"    
                                    >
                                        <img
                                            :src="item.coverUrl"
                                            alt=""
                                            class="img"
                                        />
                                    </swiper-slide>
                                </swiper>
                            </div>
                        </div>
                    </div>
					<div class="list">
						<div
                            v-for="(item,index) in desList.slice(0,6)" 
                            :key="index"
                            @click="changeDes(item)"
                            :class="item.id==curDesInfo.id ? 'des-active':''"
                            class="list-item">
                            <img :src="item.headerImgUrl" alt="" class="img">
                            <div v-if="item.id==curDesInfo.id" class="right"></div>
                            <div v-if="item.id==curDesInfo.id" class="left"></div>
                        </div>
					</div>
				</div>
			</div>
            <div class="box6">
				<div class="header f-y-c-c">
					<span class="text1">为您细节特殊定制</span>
					<span class="text2"
						>精准把控细节，铸就优质新家</span
					>
				</div>
				<div class="carousel">
                    <swiper
                        class="swiper2"
                        :options="swiperOption2"
                        @slideChange="onSlideChange2"
                        ref="mySwiper2"
                    >
                        <swiper-slide class="item f-y-c-c">
                            <img class="img" src="../image/decoration/bardian_box6_1.jpg" alt="">
                            <div class="text3 f-x-c-c">儿童保护——环保、防滑、防触电</div>
                        </swiper-slide>
                        <swiper-slide class="item f-y-c-c">
                            <img class="img" src="../image/decoration/bardian_box6_2.jpg" alt="">
                            <div class="text3 f-x-c-c">适老设计——防摔扶手，防滑地面</div>
                        </swiper-slide>
                        <swiper-slide class="item f-y-c-c">
                            <img class="img" src="../image/decoration/bardian_box6_3.jpg" alt="">
                            <div class="text3 f-x-c-c">私密空间——阻隔胶条，有效静音</div>
                        </swiper-slide>
                        <swiper-slide class="item f-y-c-c">
                            <img class="img" src="../image/decoration/bardian_box6_4.jpg" alt="">
                            <div class="text3 f-x-c-c">宠物关怀——智能投喂，宠物监控</div>
                        </swiper-slide>
                        <!-- <div class="swiper-pagination" slot="pagination"></div> -->
                    </swiper>
                    <div class="options f-x-c-c">
                        <div @click="handelOption('pre')" class="btn f-x-c-c mr34">
                            <i class="el-icon-arrow-left img1"></i>
                        </div>
                        <div @click="handelOption('next')" class="btn f-x-c-c">
                            <i class="el-icon-arrow-right img1"></i>
                        </div>
                    </div>
                </div>
			</div>
		</div>
        <Flow></Flow>
		<!-- 底部 -->
		<Footer></Footer>
        <!-- 右浮窗和底部吸顶 -->
        <Floating></Floating>
        <FloatingMob></FloatingMob>
        <VisitorForm3></VisitorForm3>
        <Tabbar></Tabbar>


        <DialogForm ref="refDialogForm" adUnitCode="D8H5"></DialogForm>
	</div>
</template>
<script>
import NavBar from '../components/NavBar.vue'
import Footer from '../components/Footer.vue'
import Flow from '../components/Flow.vue'
import VisitorForm4  from '../components/VisitorForm4.vue'
import { postmobile } from '@/api/views/name.js'
import { useSalesRoomDict,useDesDict,useCaseDict,useHouseAreasDict,useHousetypeDict,useDesStyleDict,useDesRankDict } from '@/utils/frequent.js'
export default {
	components: {
		NavBar,
		Footer,
        Flow,
        VisitorForm4
	},
	data() {
		return {
			curAddressVal: 0,
			list1: [
				{
					title: '客餐厅',
					childs: [
						'茶几',
						'电视柜',
						'主卧灯',
						'餐厅灯',
						'地板',
						'衣柜',
						'客厅灯',
						'石膏线',
						'入户门套',
						'窗台板',
						'客餐厅吊顶',
						'筒灯+灯带',
						'餐椅',
					],
					address: [],
				},
				{
					title: '主卧',
					childs: [
						'地板',
						'床头柜',
						'石膏线',
						'踢脚线',
						'衣柜',
						'开关插座',
						'主卧灯',
						'木门',
						'主卧床',
						'窗台板',
					],
					address: [],
				},
				{
					title: '次卧',
					childs: [
						'地板',
						'床头柜',
						'石膏线',
						'踢脚线',
						'衣柜',
						'开关插座',
						'次卧灯',
						'木门',
						'次卧床',
						'窗台板',
					],
					address: [],
				},
				{
					title: '厨房',
					childs: [
						'橱柜地柜',
						'燃气管',
						'调味篮',
						'水槽',
						'地砖',
						'集成吊灯',
						'双面门套',
						'热水器柜',
						'石英石包',
						'煤气管',
						'橱柜台面',
						'骑马抽',
						'碗碟篮',
						'水槽龙头',
						'墙砖',
						'集成灯',
						'过门石',
						'百叶门',
						'开关插座',
						'橱柜吊柜',
					],
					address: [],
				},
				{
					title: '卫生间',
					childs: [
						'木门',
						'地砖',
						'坐便器',
						'三角架',
						'花洒',
						'单杆毛巾架',
						'浴室柜',
						'马桶刷',
						'过门石',
						'集成吊灯',
						'淋浴屏',
						'风暖浴霸',
						'地漏',
						'开关插座',
						'纸巾架',
						'脸盆龙头',
					],
					address: [],
				},
				{
					title: '阳台',
					childs: [
						'地板',
						'洗衣机龙头',
						'灯具',
						'洗衣机地漏',
						'过门石',
						'单杆毛巾架',
						'浴室柜',
						'马桶刷',
						'过门石',
						'吸顶灯',
					],
					address: [],
				},
			],
			list2: [
				{
					desc: '安全防护 远离触电危险',
					title: '360°防护工艺',
				},
				{
					desc: '瓷砖黏贴效果更好，空间使用率更高',
					title: '空间1+1工艺',
				},
				{
					desc: '隔离楼上下水管水流声还你安静的空间',
					title: '橫无声工艺',
				},
				{
					desc: '卫生间会被湿气入侵房间干爽舒适',
					title: '门边泛水隔离工艺',
				},
				{
					desc: '不会影响网络等弱电信号信号满格的安全感',
					title: '抗干扰工艺',
				},
				{
					desc: '整屋墙面挂网格布防止墙面油漆开裂',
					title: '零开裂挂网工艺',
				},
				{
					desc: '卫生间安装双重防臭地漏给你的卫生间不一样',
					title: '双重防臭工艺',
				},
				{
					desc: '提高热效率节约能源更为你省钱',
					title: '热能保护工艺',
				},
				{
					desc: '墙边阳角采用护角条使墙角不会因磕碰而受损',
					title: '阳角零伤害工艺',
				},
				{
					desc: '高规格材料高规格安装工艺高规格成品确保',
					title: '顶面零开裂工艺',
				},
				{
					desc: '每一块地板都要五龙悬浮铺贴好脚感更要',
					title: '五龙悬浮竹炭包工艺',
				},
				{
					desc: '横竖十字交叉的防水工艺无缝防水盛水不漏',
					title: '盛水不漏工艺',
				},
				{
					desc: '回形切割+2倍坡度无死角迅速下水更舒心',
					title: '2*2极速下水',
				},
				{
					desc: '新建墙体拉墙筋为你打造安 全稳固的港湾',
					title: '双排连接工艺',
				},
				{
					desc: '浴室柜背部加插口式挂板45°加固承重柜',
					title: '45°加固保护',
				},
				{
					desc: '大圆弧排管抽拉灵活不伤线替换维修更便捷',
					title: '月亮弯工艺',
				},
				{
					desc: '黄墙绿地清爽干净全景实样 提前看家里',
					title: '1:1数字放样',
				},
				{
					desc: '玄关一键开关',
					desc2: '整屋智能享受',
					title: '智能一键开关',
				},
				{
					desc: '洗手台洗碗池3秒出热水暖流由指尖直达全',
					title: '3秒热水工艺',
				},
			],
			tabList: [
				{
					value: 1,
					url: require('../image/decoration/bardian_box3_1.jpg'),
					title: '现代简约 ',
				},
				{
					value: 2,
					url: require('../image/decoration/bardian_box3_2.jpg'),
					title: '新中式',
				},
				{
					value: 3,
					url: require('../image/decoration/bardian_box3_3.jpg'),
					title: '北欧风',
				},
				{
					value: 4,
					url: require('../image/decoration/bardian_box3_4.jpg'),
					title: '美式轻奢',
				},
				{
					value: 5,
					url: require('../image/decoration/bardian_box3_5.jpg'),
					title: '意式极简',
				},
			
			],

            list3:[
                {
                    url:require('../image/decoration/bardian_box4_icon1.jpg'),
                    title:"收纳系统",
                    des:"玄关柜、衣柜、储藏柜、橱柜、洁柜等，让您的生活井井有条。"
                },
                {
                    url:require('../image/decoration/bardian_box4_icon2.jpg'),
                    title:"结构设计",
                    des:"合理利用空间，确保室内空间的分配、通风、采光等，以提供舒适的使用体验。"
                },
                {
                    url:require('../image/decoration/bardian_box4_icon3.jpg'),
                    title:"舒适设计",
                    des:"软硬装设计互补，空间光线色彩等合理搭配，打造独到格调的室内空间。"
                },
                {
                    url:require('../image/decoration/bardian_box4_icon4.jpg'),
                    title:"风格设计",
                    des:"同步寰球潮流趋势，效果图复制呈现，优雅、华奢、古典、简约、百变风格。"
                },
                {
                    url:require('../image/decoration/bardian_box4_icon5.jpg'),
                    title:"空间规划",
                    des:"卧室、客厅、书房、卫浴、厨房空间的合理利用，舒适美观与居住私享兼容。"
                },
                
            ],
			curTab: 0,
            desList:[],
            swiperOption1: {
				slidesPerView: 3,
				spaceBetween: '2%',
			},
            swiperOption2: {
                spaceBetween: '5%',
                effect: 'coverflow',
                grabCursor: true,
                centeredSlides: true,
                initialSlide: 1,
                // slidesPerView: 2.5,
                slidesPerView: 'auto',
                autoplay:true,
                loop:true,
                coverflowEffect: {
                    rotate: 20,
                    stretch: 0,
                    depth: 0,
                    modifier: 1,
                    slideShadows : true
                },
                pagination: {
                    el: '.swiper2 .swiper-pagination'
                }
            },
            optionSmall:{
                loop: true,
                slidesPerView: 1,
                // initialSlide:2,
                effect: 'fade',
                loopedSlides: 6, // looped slides should be the same
                spaceBetween: 10,
                // initialSlide:2,
                observer: true, //修改swiper自己或子元素时，自动初始化swiper
                observeParents: true, //修改swiper的父元素时，自动初始化swiper
                autoplay:true,
                
            },
            curDesInfo:{},
            curCaseList:[],
            caseList:[],
            swiper2Index:1
		}
	},
    created() {},
	async mounted() {
        if(this.curCityInfo.value=='00'){
            this.desList = (await useDesDict()).filter(item=>item.isHeadOffice ==1)
            this.caseList = (await useCaseDict({size:500,current:1})).filter(item=>item.isHeadOffice ==1)
        }else{
            this.desList = (await useDesDict()).filter(item=>item.areaCode ==this.curCityInfo.value)
            this.caseList = (await useCaseDict({size:500,current:1})).filter(item=>item.areaCode ==this.curCityInfo.value)
        }
        
        this.changeDes(this.desList[0])
    },
	methods: {
        clickSmall(index){
            this.$refs.refSmall.$swiper.slideTo(index)
        },
        openDialog(){
            this.$refs.refDialogForm.showDialog('design')
        },
        changeDes(data){
            this.curDesInfo = data
            this.curCaseList = this.caseList.filter(item=>item.designerId==data.id)
            
        },
		cahngeTabs(index) {
			this.curTab = index
		},
        onSlideChange2() {
			this.swiper2Index = this.$refs.mySwiper2.$swiper.activeIndex
		},
        handelOption(data) {
			if (data == 'pre')
				// this.$refs.mySwiper2.$swiper.slideTo(this.swiper2Index - 1)
				this.$refs.mySwiper2.$swiper.slidePrev()
			if (data == 'next')
				// this.$refs.mySwiper2.$swiper.slideTo(this.swiper2Index + 1)
				this.$refs.mySwiper2.$swiper.slideNext()
		},
	},
}
</script>
<style lang="less" scoped>
@media screen and (max-width: 899px) {
    .bardian {
        overflow-x: hidden;
		.box {
            display: none;
			
		}
        .box-mob{
            width: 100%;
			height: 200px;
			margin-top: 43px;
			.bran {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
        }
        .content {
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			justify-content: center;
			background: #ffffff;
            .box1 {
                margin: 0 12px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-flow: column;
				width:calc(100% - 24px) ;
				padding: 30px;
				box-sizing: border-box;
				margin-top: 50px;
				box-sizing: border;
				background:no-repeat center/cover url(../image/decoration/bardian_box1_bg.jpg);
				// background-size: 100% 100%;
				// background-repeat: no-repeat;
				border-radius: 9px;

                .form-box{
                    width: 300px;
					height: 340px;
                }
				.form {
					width: 300px;
					height: 340px;
					background: #ffffff;
					border-radius: 20px;
					box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.16);
					overflow: hidden;
					padding: 30px;
					box-sizing: border-box;
					.title {
						
						font-weight: bold;
						font-size: 18px;
						color: #140626;
						line-height: 18px;
					}
					.desc {
						
						font-weight: 400;
						font-size: 14px;
						color: #140626;
						margin-top: 9px;
					}
					.form-box {
						margin-top: 16px;
						width: 100%;
						.phone {
							/deep/.el-input__inner {
								padding-left: 15px !important;
							}
						}
						.input {
							width: 100%;
							margin-bottom: 15px;
							.req {
								color: #e30c0c;
								height: 40px;
								line-height: 40px;
							}
							/deep/ .el-input {
								width: 100%;
								height: 40px;

								.el-input__inner {
									width: 100%;
									height: 40px;
								}
							}
							/deep/ .el-input__inner ,/deep/ .el-input__inner::placeholder {
								width: 100%;
								height: 40px;

                                
                                font-weight: 300;
                                font-size: 12px !important;
                                color: #444444 !important;
                                line-height: 40px !important;
							}

							/deep/.el-input__suffix {
								right: 10px;
								.m2 {
									height: 40px;
									line-height: 40px;
									font-size: 12px;
								}
							}
							/deep/.el-icon-arrow-down {
								font-size: 12px !important;
							}
						}
						.el-cascader {
							/deep/.el-input__suffix {
								right: 15px !important;
							}
						}
					}
					.btn {
						width: 100%;
						height: 40px;
						background: #fd8b2f;
						border-radius: 4px;
						
						font-weight: bold;
						font-size: 15px;
						color: #ffffff;
						line-height: 15px;
						cursor: pointer;
					}
				}
				.img1 {
                    margin-top: 30px;
					width: 300px;
					height: 300px;
                    object-fit: cover;
				}
			}
			.box2 {
				margin-top: 50px;
				// padding: 0 180px;
				width: 100%;
				// height: 1300px;
				box-sizing: border-box;
				background: #fff;
				.header {
					.text1 {
						
						font-weight: bold;
						font-size: 24px;
						color: #120523;
					}
					.text2 {
						margin-top: 5px;
						
						font-weight: 400;
						font-size: 12px;
						color: #444444;
					}
				}
				.box2-content {
                    margin-top: 20px;
					width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;
                    padding: 0 12px;
                    box-sizing: border-box;
                    .list-item{
                        width: 100%;
                        // height: 180px;
                        position: relative;
                        margin-bottom: 12px;
                        .img1{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                        .title{
                            position: absolute;
                            top: 10px;
                            left: 0;
                            width: 90px;
                            height: 25px;
                            background: #753CBE;
                            border-radius: 0 20px 20px 0;
                            
                            font-weight: 400;
                            font-size: 13px;
                            color: #FFFFFF;
                        }
                    }
                }
					
			}
			.box3 {
				background: #fff;
				width: 100%;
				margin-top: 50px;
				.header {
					.text1 {
						
						font-weight: bold;
						font-size: 24px;
						color: #120523;
					}
					.text2 {
						margin-top: 5px;
						
						font-weight: 400;
						font-size: 12px;
						color: #444444;
					}
				}
				.box3-content {
					width: 100%;
					margin-top: 20px;
					.tab-list {
						width: 100%;
						padding: 0 10px;
						box-sizing: border-box;
						.list-item {
                            // width: 60px;
                            padding: 0 10px;
                            height: 30px;
							cursor: pointer;
                            
                            font-weight: 400;
                            font-size: 14px;
                            color: #444444;
						}
						.tab-active {
                            // width: 60px;
                            height: 30px;
                            background: #753CBE;
                            border-radius: 30px;
                            color: #FFFFFF;
						}
					}
					.banner {
						margin-top: 15px;
						width: 100%;
						height: 200px;
						.img4 {
							width: 100%;
							height: 100%;
                            object-fit: cover;
						}
					}
				}
			}
			.box4 {
				background: #fff;
				width: 100%;
				margin-top: 50px;
				.header {
					.text1 {
						
						font-weight: bold;
						font-size: 24px;
						color: #120523;
					}
					.text2 {
						margin-top: 5px;
						
						font-weight: 400;
						font-size: 12px;
						color: #444444;
					}
				}
                .box4-content1{
                    padding: 0 13px;
                    width: 100%;
                    box-sizing: border-box;
                    position: relative;
                    .swiper{
                        width: 100%;
                    }
                    
                    .small-img{
                        width: 100%;
                        height: 160px;
                        border-radius: 3px;
                    }
                    .img-list{
                        padding: 0 53px;
                        width: 100%;
                        box-sizing: border-box;
                        // margin-top: -20px;
                        z-index: 99;
                        position: absolute;
                        top: 140px;
                        .list-item{
                            width: 40px;
                            height: 40px;
                            background: #FFFFFF;
                            border: 2px solid #FFFFFF;
                            border-radius: 50%;
                            overflow: hidden;
                            .item-img{
                                width: 40px;
                                height: 40px;
                                object-fit: cover;
                            }
                        }
                    }
                    /deep/ .swiper-slide-active{
                        .triangle0{
                            left: 53px;
                            display: block !important;
                        }
                        .triangle1{
                            left: 110px;
                            display: block !important;
                        }
                        .triangle2{
                            left: 167px;
                            display: block !important;
                        }
                        .triangle3{
                            left: 224px;
                            display: block !important;
                        }
                        .triangle4{
                            left: 281px;
                            display: block !important;
                        }
                    }
                    .small-title{
                        width: 100%;
                        box-sizing: border-box;
                        position: relative;
                        .triangle{
                            position: absolute;
                            // left: 110px;
                            top: -16px;
                            width: 0;
                            height: 0;
                            border-top: 10px solid transparent;
                            border-bottom: 8px solid #753CBE;
                            // border-bottom: 8px solid #1349fa;
                            border-right: 8px solid transparent;
                            border-left: 8px solid transparent;
                            display: none;
                        }
                        
                        
                        .title-content{
                            width: 100%;
                            height: 95px;
                            background: #753CBE;
                            padding: 0 13px;
                            box-sizing: border-box;
                            border-radius: 3px;
                            .title-top{
                                font-size: 15px;
                                
                                font-weight: bold;
                                color: #FFFFFF;
                                line-height: 15px;
                                .line{
                                    width: 233px;
                                    background: #E5E5E5;
                                    transform: scaleY(0.5);
                                }
                            }
                            .title-bottom{
                                font-size: 12px;
                                
                                font-weight: 400;
                                color: #FFFFFF;
                            }
                        }
                    }
                }
				.box4-content {
                    display: none;
				}
			}
            .box5{
                background: #fff;
				width: 100%;
				margin-top: 50px;
				.header {
					.text1 {
						
						font-weight: bold;
						font-size: 24px;
						color: #120523;
					}
					.text2 {
						margin-top: 5px;
						
						font-weight: 400;
						font-size: 12px;
						color: #444444;
					}
				}
				.box5-content {
                    display: flex;
                    flex-flow: column;
                    justify-content: center;
                    align-items: center;
					width: 100%;
                    // height: 500px;
					margin-top: 20px;
                    padding: 0 10px 40px 10px;
                    box-sizing: border-box;
                    background:no-repeat center/cover url(../image/decoration/bardian_box5_bg.jpg);
                    .info{
                        width: 100%;
                        // height: 300px;
                        background: #FFFFFF;
                        box-shadow: 0px 12px 20px 0px rgba(4,0,0,0.11);
                        border-radius: 8px;
                        padding: 10px;
                        box-sizing: border-box;
                        position: relative;
                        .top{
                            width: 100%;
                            .info-img{
                                width: 110px;
                                height: 140px;
                                margin-right: 10px;
                                object-fit: cover;
                            }
                            .info-desc{
                                flex: 1;
                                padding-top: 5px;
                                height: 100%;
                                box-sizing: border-box;
                                .header{
                                    .text1{
                                        
                                        font-weight: bold;
                                        font-size: 18px;
                                        color: #120523;
                                        line-height: 18px;
                                        margin-right: 10px;
                                    }
                                    .text2{
                                        
                                        font-weight: 400;
                                        font-size: 15px;
                                        color: #120523;
                                        line-height: 18px;
                                        margin-top: 0;
                                    }
                                }
                                .text3{
                                    
                                    font-weight: 400;
                                    font-size: 13px;
                                    color: #120523;
                                    line-height: 13px;
                                    margin-top: 10px;
                                }
                                .text4{
                                    
                                    font-weight: 400;
                                    font-size: 13px;
                                    color: #120523;
                                    line-height: 16px;
                                    margin-top: 10px;
                                    flex: 1;
                                }
                                .btn{
                                    width: 90px;
                                    height: 30px;
                                    background: #753CBE;
                                    margin-top:20px;
                                    
                                    font-weight: 400;
                                    font-size: 13px;
                                    color: #FFFFFF;
                                    cursor: pointer;
                                }
                            }
                        }
                        .bottom{
                            margin-top: 15px;
                            width: 100%;
                            .header{
                                width: 100%;
                                .title{
                                    
                                    font-weight: 500;
                                    font-size: 14px;
                                    color: #666666;
                                    margin-right: 5px;
                                }
                                .line{
                                    flex: 1;
                                    height: 1px;
                                    background: #E5E5E5;
                                }
                            }
                            .carousel{
                                margin-top: 16px;
                                height: 80px;
                                width: 100%;
                                .swiper{
                                    height: 100%;
                                }
                                .img{
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }
                        }
                        // .jiao{
                        //     position: absolute;
                        //     width: 0;
                        //     height: 0;
                        //     border:25px solid;
                        //     right:-50px;
                        //     top: 100px;
                        //     border-color: transparent transparent transparent #ffffff ;
                        // }
                    }   
                    .list{
                        width: 100%;
                        height: 60px;
                        display: grid;
                        margin-top: 10px;
                        grid-template-columns: repeat(5,1fr);
                        gap: 0px 10px;
                        .list-item{
                            cursor: pointer;
                            // width: 230px;
                            height: 60px;
                            border: 1px solid #fff;
                            box-sizing: border-box;
                            position: relative;
                            padding: 1px;
                            background: #fff;
                            .img{
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                            .right{
                                position: absolute;
                                width: 0;
                                height: 0;
                                top: -10px;
                                right: -10px;
                                border: 10px solid;
                                // border-color: #FD8B2F transparent transparent transparent ;
                                border-color:  transparent transparent #FD8B2F transparent ;
                                transform: rotate(45deg)
                            }
                            .left{
                                position: absolute;
                                width: 0;
                                height: 0;
                                bottom: -10px;
                                left: -10px;
                                border: 10px solid;
                                // border-color: #FD8B2F transparent transparent transparent ;
                                border-color:  transparent #FD8B2F transparent  transparent ;
                                transform: rotate(-45deg)
                            }
                        }
                        .des-active{
                            border: 2px solid #FD8B2F !important;
                        }
                    }
                }
                
                
            }
            .box6{
                background: #fff;
				width: 100%;
				margin-top: 50px;
				margin-bottom: 50px;
				.header {
					.text1 {
						
						font-weight: bold;
						font-size: 24px;
						color: #120523;
					}
					.text2 {
						margin-top: 5px;
						
						font-weight: 400;
						font-size: 12px;
						color: #444444;
					}
				}
				.carousel {
					width: 100%;
                    // height: 400px;
					margin-top: 20px;
                    box-sizing: border-box;
                    // padding-top: 50px;
                    // padding-bottom: 50px;
                    .swiper2{
                        height: 100%;
                        height: 240px;

                        .swiper-slide{
                            border-radius: 4px;
                            overflow: hidden;
                            // height: 580px;
                            // width: 958px;



                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 300px;
                            height: 240px;
                            text-align: center;
                            font-weight: bold;
                            /deep/.img{
                                height: 200px;
                                width: 300px;
                                border-radius: 4px 4px 0 0;
                                object-fit: cover;
                            }
                            /deep/.text3{
                                width: 300px;
                                height: 40px;
                                background: #753CBE;
                                border-radius:  0 0 4px 4px;

                                
                                font-weight: bold;
                                font-size: 16px;
                                color: #FFFFFF;
                            }
                        }
                        
                    }
                    .options {
                        margin-top: 30px;
                        .btn {
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            border: 1px solid #e5e5e5;
                            cursor: pointer;
                            .img1 {
                                font-size: 25px;
                                font-weight: bold;
                            }
                        }
                        .btn:hover {
                            border: 1px solid #753cbe;
                            .img1 {
                                color: #753cbe;
                            }
                        }
                    }
                   
                }
                
                
            }
		}
    }
}
@media screen and (min-width: 900px) {
	.bardian {
		.box {
			width: 100%;
			height: 680px;
			// margin-top: 96px;
			.bran {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
        .box-mob{
            display: none;
        }
		.content {
			width: 100%;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			justify-content: center;
			background: #ffffff;
			.box1 {
				width: 1556px;
				padding: 67px 180px;
				box-sizing: border-box;
				margin-top: 130px;
				box-sizing: border;
				background: url(../image/decoration/bardian_box1_bg.jpg);
				background-size: 100% 100%;
				background-repeat: no-repeat;
				border-radius: 18px;
                .form-box{
                    width: 540px;
					height: 640px;
                }
				.img1 {
					width: 550px;
					height: 550px;
				}
			}
			.box2 {
				margin-top: 130px;
				padding: 0 180px;
				width: 100%;
				// height: 1300px;
				box-sizing: border-box;
				background: #fff;
				.header {
					.text1 {
						
						font-weight: bold;
						font-size: 72px;
						color: #120523;
					}
					.text2 {
						margin-top: 25px;
						
						font-weight: 400;
						font-size: 30px;
						color: #444444;
					}
					.text3 {
						margin-top: 20px;
						
						font-weight: 400;
						font-size: 30px;
						color: #444444;
					}
				}
				.box2-content {
                    margin-top: 37px;
					width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;
                    .list-item{
                        width: 760px;
                        height: 510px;
                        position: relative;
                        margin-bottom: 36px;
                        .img1{
                            width: 100%;
                            height: 100%;
                        }
                        .title{
                            position: absolute;
                            top: 30px;
                            left: 0;
                            width: 239px;
                            height: 86px;
                            background: #753CBE;
                            border-radius: 0 50px 50px 0;
                            
                            font-weight: 400;
                            font-size: 48px;
                            color: #FFFFFF;
                        }
                    }
                }
					
			}
			.box3 {
				background: #fff;
				width: 100%;
				margin-top: 130px;
				.header {
					.text1 {
						
						font-weight: bold;
						font-size: 72px;
						color: #120523;
					}
					.text2 {
						margin-top: 25px;
						
						font-weight: 400;
						font-size: 30px;
						color: #444444;
					}
				}
				.box3-content {
					width: 100%;
					margin-top: 40px;
					.tab-list {
						width: 100%;
						padding: 0 380px;
						box-sizing: border-box;
						.list-item {
                            width: 235px;
                            height: 76px;
							cursor: pointer;
                            
                            font-weight: 400;
                            font-size: 40px;
                            color: #444444;
						}
						.tab-active {
                            width: 235px;
                            height: 76px;
                            background: #753CBE;
                            border-radius: 38px;
                            color: #FFFFFF;
						}
					}
					.banner {
						margin-top: 28px;
						width: 100%;
						height: 960px;
						.img4 {
							width: 100%;
							height: 100%;
						}
					}
				}
			}
			.box4 {
               
				background: #fff;
				width: 100%;
				margin-top: 130px;
				.header {
					.text1 {
						
						font-weight: bold;
						font-size: 72px;
						color: #120523;
					}
					.text2 {
						margin-top: 25px;
						
						font-weight: 400;
						font-size: 30px;
						color: #444444;
					}
				}
                .box4-content1{
                    display: none;
                }
				.box4-content {
                    display: flex;
                    justify-content: center;
                    align-items: center;
					width: 100%;
                    height: 960px;
					margin-top: 50px;
                    padding: 0 180px;
                    box-sizing: border-box;
                    .left{
                        width: 980px;
                        min-width: 980px;
                        height: 960px;
                        border-radius: 18px;
                        overflow: hidden;
                        .img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
					.list{
                        border-radius: 0 80px 80px 0;
                        // width: 1120px;
                        height: 100%; 
                        display: flex;
                        flex-flow: column;
                        justify-content: space-between;
                        padding: 74px 0;
                        box-sizing: border-box;
                        margin-left: -71px;
                        .list-item{
                            margin-bottom: 26px;
                            .img{
                                width: 142px;
                                height: 142px;
                                border-radius: 50%;
                                border:5px solid #fff;
                                box-sizing: border-box;
                            }
                            .info{
                                margin-left: 26px;
                                .text3{
                                    
                                    font-weight: bold;
                                    font-size: 32px;
                                    color: #000000;
                                }
                                .text4{
                                    
                                    font-weight: 400;
                                    font-size: 24px;
                                    color: #000000;
                                    margin-top: 10px;
                                }
                            }
                        }
                    }
				}
			}
            .box5{
                background: #fff;
				width: 100%;
				margin-top: 130px;
				.header {
					.text1 {
						
						font-weight: bold;
						font-size: 72px;
						color: #120523;
					}
					.text2 {
						margin-top: 25px;
						
						font-weight: 400;
						font-size: 30px;
						color: #444444;
					}
				}
				.box5-content {
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;

					width: 100%;
                    height: 1156px;
					margin-top: 40px;
                    padding: 0 180px;
                    box-sizing: border-box;
                    background: url(../image/decoration/bardian_box5_bg.jpg);
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    .info{
                        width: 1058px;
                        height: 880px;
                        background: #FFFFFF;
                        box-shadow: 0px 12px 20px 0px rgba(4,0,0,0.11);
                        border-radius: 15px;
                        padding: 40px;
                        box-sizing: border-box;
                        position: relative;
                        margin-right: 50px;
                        .top{
                            width: 100%;
                            .info-img{
                                width: 440px;
                                height: 516px;
                                margin-right: 40px;
                                object-fit: cover;

                            }
                            .info-desc{
                                flex: 1;
                                padding-top: 74px;
                                height: 100%;
                                box-sizing: border-box;
                                .header{
                                    .text1{
                                        
                                        font-weight: bold;
                                        font-size: 60px;
                                        color: #120523;
                                        line-height: 60px;
                                        margin-right: 30px;
                                    }
                                    .text2{
                                        
                                        font-weight: 400;
                                        font-size: 30px;
                                        color: #120523;
                                        line-height: 30px;
                                    }
                                }
                                .text3{
                                    
                                    font-weight: 400;
                                    font-size: 30px;
                                    color: #120523;
                                    line-height: 30px;
                                    margin-top: 35px;
                                }
                                .text4{
                                    
                                    font-weight: 400;
                                    font-size: 30px;
                                    color: #120523;
                                    line-height: 48px;
                                    margin-top: 36px;
                                    flex: 1;
                                }
                                .btn{
                                    width: 280px;
                                    height: 100px;
                                    background: #753CBE;
                                    margin-top:70px;
                                    
                                    font-weight: 400;
                                    font-size: 36px;
                                    color: #FFFFFF;
                                    cursor: pointer;
                                }
                            }
                        }
                        .bottom{
                            margin-top: 30px;
                            width: 100%;
                            .header{
                                width: 100%;
                                .title{
                                    
                                    font-weight: 500;
                                    font-size: 28px;
                                    color: #666666;
                                    margin-right: 10px;
                                }
                                .line{
                                    flex: 1;
                                    height: 1px;
                                    background: #E5E5E5;
                                }
                            }
                            .carousel{
                                margin-top: 32px;
                                height: 200px;
                                width: 100%;
                                .swiper{
                                    height: 100%;
                                }
                                .img{
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }
                        }
                        .jiao{
                            position: absolute;
                            width: 0;
                            height: 0;
                            border:25px solid;
                            right:-50px;
                            top: 100px;
                            border-color: transparent transparent transparent #ffffff ;


                            // position: absolute;
                            // right:-50px;
                            // top: 100px;
                            // width: 100px;
                            // height: 100px;
                            // background: red;
                            // clip-path: polygon(0 0,0 100%,100% 100%);
                            // box-shadow: -20px 12px 20px 0px rgba(25, 0, 255, 0.91);
                        }
                    }   
                    .list{
                        width: 480px;
                        height: 880px;
                        display: grid;
                        grid-template-columns: auto auto;
                        grid-template-rows: repeat(3,1fr);
                        gap: 20px 20px;
                        .list-item{
                            cursor: pointer;
                            width: 230px;
                            height: 280px;
                            border: 3px solid #fff;
                            box-sizing: border-box;
                            position: relative;
                            padding: 2px;
                            background: #fff;
                            .img{
                                width: 100%;
                                height: 100%;
                                object-fit: cover
                            }
                            .right{
                                position: absolute;
                                width: 0;
                                height: 0;
                                top: -50px;
                                right: -50px;
                                border: 50px solid;
                                // border-color: #FD8B2F transparent transparent transparent ;
                                border-color:  transparent transparent #FD8B2F transparent ;
                                transform: rotate(45deg)
                            }
                            .left{
                                position: absolute;
                                width: 0;
                                height: 0;
                                bottom: -50px;
                                left: -50px;
                                border: 50px solid;
                                // border-color: #FD8B2F transparent transparent transparent ;
                                border-color:  transparent #FD8B2F transparent  transparent ;
                                transform: rotate(-45deg)
                            }
                        }
                        .des-active{
                            border: 3px solid #FD8B2F !important;
                        }
                    }
                }
                
                
            }
            .box6{
                background: #fff;
				width: 100%;
				margin-top: 130px;
				margin-bottom: 130px;
				.header {
					.text1 {
						
						font-weight: bold;
						font-size: 72px;
						color: #120523;
					}
					.text2 {
						margin-top: 25px;
						
						font-weight: 400;
						font-size: 30px;
						color: #444444;
					}
				}
				.carousel {
					width: 100%;
                    height: 900px;
					margin-top: 40px;
                    box-sizing: border-box;
                    // padding-top: 50px;
                    // padding-bottom: 50px;
                    .swiper2{
                        height: 100%;
                        height: 700px;

                        .swiper-slide{
                            border-radius: 8px;
                            overflow: hidden;
                            // height: 580px;
                            // width: 958px;



                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 958px;
                            height: 700px;
                            text-align: center;
                            font-weight: bold;
                            /deep/.img{
                                height: 580px;
                                width: 958px;
                                border-radius: 8px 8px 0 0;
                            }
                            /deep/.text3{
                                width: 958px;
                                height: 120px;
                                background: #753CBE;
                                border-radius:  0 0 8px 8px;

                                
                                font-weight: bold;
                                font-size: 48px;
                                color: #FFFFFF;
                            }
                        }
                        
                    }
                    .options {
                        margin-top: 60px;
                        .btn {
                            width: 125px;
                            height: 125px;
                            border-radius: 50%;
                            border: 1px solid #e5e5e5;
                            cursor: pointer;
                            .img1 {
                                font-size: 60px;
                                font-weight: bold;
                            }
                        }
                        .btn:hover {
                            border: 1px solid #753cbe;
                            .img1 {
                                color: #753cbe;
                            }
                        }
                    }
                   
                }
                
                
            }
		}
	}
}
</style>
